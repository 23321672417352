import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-000/index.vue';
import BackBtn from '@/component/backBtn/index.vue'
import consumer_journey_payCenterDetails from '@/lib/data-service/haolv-default/consumer_journey_payCenterDetails'
import consumer_admin_flight_checkFlightPay from '@/lib/data-service/haolv-default/consumer_admin_flight_checkFlightPay'
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import aes_encrypt from 'nllib/src/common/aes/aes_encrypt';
import wait_some_seconds from 'nllib/src/common/wait_some_seconds';
import Q from 'q';
export default {
    data() {
        return {
            evectionNo: '',
            orderNos: [],
            flightOrderList: [],
            errorDialog: false,
            errorText: '',
            orderType: '',// 为空字符是整个出差单，1是机票订单，2是火车票订单，3是酒店订单
            pageType: '',// 1是创建订单页，2是支付页，3是改签页
        }
    },
    components: {PaymentMethodSelector000, BackBtn},
    created() {
    },
    mounted() {
        this.init()
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        init() {
            this.evectionNo = this.$route.query.evectionNo ? this.$route.query.evectionNo : ''
            this.orderNos = this.$route.query.orderNos ? this.$route.query.orderNos.split(',') : []
            console.log(this.orderNos)
            this.flightOrderList = this.$route.query.flightOrderList ? this.$route.query.flightOrderList.split(',') : []
            this.orderType = Number(this.$route.query.orderType) || '';
            this.pageType = Number(this.$route.query.pageType) || 2;
            // this.getDetail()
            this.payment_init()
        },
        toInit() {
            this.errorDialog = false
            this.init()
        },
        async getDetail() {
            const params = {
                evectionNo: this.evectionNo
            }
            let [err, res] = await awaitWrap(consumer_journey_payCenterDetails(params))
            if (err) {
                return
            }
            this.evectionInfo = res.datas
            this.payment_init()
        },
        payment_init() {
            const __this = this;
            let orderForPayVos = []
            this.orderNos.forEach(value => {
                const params = {
                    orderNo: value,
                    businessContent: ''
                }
                orderForPayVos.push(params)
            })
            //支付
            const init_000 = function () {
                __this.$refs.aPaymentMethodSelector000.init({
                    // btn_pay: {
                    //     text: `支付（自定义文本）`,
                    // },
                    page_this: __this,
                    //在这里传入订单详情的相关参数
                    get_params() {
                        const p = {
                            "orderForPayVos": orderForPayVos,
                            "payType":  __this.pageType !== 3 ? 1 : 2
                        };
                        return Q.resolve(p);
                    },

                    //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                    before_pay_event_handler() {
                        // 机票单验价
                        if (__this.flightOrderList.length > 0) {
                            // 如果有机票订单，则验价
                            return consumer_admin_flight_checkFlightPay({orderNo: __this.flightOrderList}).then(res => {
                                return Q.resolve();
                            }, error => {
                                // 200029  变价
                                __this.errorText = error.data.msg
                                __this.errorDialog = true
                                //__this.init()
                                return Q.reject()
                            })
                        } else {
                            return Q.resolve();
                        }
                    },

                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        console.log(`pay_successful_event_handler`);
                        __this.$router.push({
                            name: 'admin-pay-success',
                        })
                    },

                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        console.log(`pay_failed_event_handler`);
                    },

                    //取消订单按钮单击事件处理方法
                    btn_cancel_order_click_event_handler(args) {
                        const show_loading = args.show_loading;
                        const hide_loading = args.hide_loading;
                        const stop_count_down = args.stop_count_down;
                        const enable_btn_pay = args.enable_btn_pay;
                        const enable_btn_cancel_order = args.enable_btn_cancel_order;

                        Q.when()
                            .then(function () {
                                show_loading();
                                return wait_some_seconds({
                                    seconds: 2,
                                });
                            })
                            .then(function () {
                                console.log(`btn_cancel_order_click_event_handler`);
                                hide_loading();
                                stop_count_down();
                                enable_btn_pay({
                                    enable: false,
                                });
                                enable_btn_cancel_order({
                                    enable: false,
                                });
                            })
                            .catch(function (ex) {
                                if (ex) {
                                    const enable_log_error = !ex.disable_log_error;
                                    if (enable_log_error) {
                                        console.error(ex);
                                        console.trace();
                                        if (ex.stack) {
                                            console.error(ex.stack);
                                        }
                                    }
                                }
                                return Q.reject(ex);
                            })
                        ;
                    },
                    //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                    dialog_code_300052_btn_ok_click_event_handler() {
                        console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                        __this.$router.replace({
                            name: `admin-my-evection`,
                        });
                        return Q.resolve(true);
                        // return true;
                    },
                });
            };
            init_000();
        },
        getBack() {
            this.errorDialog = false
            this.$router.go(-1)
        },
    }
}
